<template>
  <Exp></Exp>
</template>

<script>
import Exp from "../components/Exp.vue";

export default {
  name: "Experience",

  components: {
    Exp,
  },
};
</script>
